import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'agaReplaceSpacesInString'
})
export class ReplaceSpacesInStringPipe implements PipeTransform {
	transform(value: string, char = ''): string {
		return value.split(' ').join(char);
	}
}
